import React from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from './firebase';

const SignIn = ({ onLogin }) => {
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken(); // Get Firebase ID Token
      onLogin(token); // Pass the token to the parent component
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  return <button onClick={signInWithGoogle}>Sign in with Google</button>;
};

export default SignIn;
