import { useEffect, useRef, useState } from "react";
// import { FiLock } from "react-icons/fi";
import { motion } from "framer-motion";
import wand from '../assets/wand.png';


const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;

const CHARS = "!@#$%^&*():{};|,.<>/?";

const EncryptButton = ({ label = "Default Cast", onClick, disabled }) => {
  const intervalRef = useRef(null);

  const [text, setText] = useState('');

  useEffect(() => { scramble() }, [])

  const scramble = () => {
    let pos = 0;

    intervalRef.current = setInterval(() => {
      const scrambled = label.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(scrambled);
      pos++;

      if (pos >= label.length * CYCLES_PER_LETTER) {
        stopScramble();
      }
    }, SHUFFLE_TIME);
  };

  const stopScramble = () => {
    clearInterval(intervalRef.current || undefined);

    setText(label);
  };

  return (
    <motion.button
      whileHover={{
        scale: disabled ? 1 : 1.025,
      }}
      whileTap={{
        scale: disabled ? 1 : 0.975,
      }}
      onMouseEnter={scramble}
      onMouseLeave={stopScramble}
      className="inline-flex whitespace-nowrap w-full px-3.5 py-2.5 text-sm font-medium text-warmGray-200 dark:text-warmGray-800 bg-gradient-to-r from-warmGray-800 to-warmGray-700 dark:from-warmGray-200 dark:to-warmGray-100 dark:hover:bg-warmGray-100 shadow focus:outline-none focus:ring focus:ring-warmGray-500/50 focus-visible:outline-none focus-visible:ring focus-visible:ring-warmGray-500/50 relative before:absolute before:inset-0 before:rounded-[inherit] before:bg-[linear-gradient(45deg,transparent_25%,theme(colors.white/.2)_50%,transparent_75%,transparent_100%)] dark:before:bg-[linear-gradient(45deg,transparent_25%,theme(colors.white)_50%,transparent_75%,transparent_100%)] before:bg-[length:250%_250%,100%_100%] before:bg-[position:200%_0,0_0] before:bg-no-repeat before:[transition:background-position_0s_ease] hover:before:bg-[position:-100%_0,0_0] hover:before:duration-[1500ms]" 
      onClick={onClick}
      disabled={disabled}
    >
      <div className="relative z-10  gap-2 ">
        {/* <FiLock /> */}
        <span className="font-normal flex items-center pl-4"> <img className=" h-10 mr-4 bg-[radial-gradient(circle_at_60%_30%,rgba(6,95,70,1)_0%,rgba(6,95,70,0)_50%)]" src={wand}/> {disabled? 'magicking': text}</span>
      </div>
      {/* <motion.span
        initial={{
          y: "100%",
        }}
        animate={{
          y: "-100%",
        }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1,
          ease: "linear",
        }}
      className="duration-300 absolute inset-0 z-0 scale-125 bg-gradient-to-t from-indigo-400/0 from-40% via-indigo-400/100 to-indigo-400/0 to-60% opacity-0 transition-opacity group-hover:opacity-100"
      /> */}
    </motion.button>
  );
};

export default EncryptButton;