import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import DeckGL from '@deck.gl/react';
import Map, { useControl } from 'react-map-gl';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { ScatterplotLayer, TextLayer, ArcLayer, IconLayer } from '@deck.gl/layers';
import { IcoSphereGeometry } from '@luma.gl/engine';
import { OBJLoader } from '@loaders.gl/obj';
import { SimpleMeshLayer } from 'deck.gl';
import * as Zustand from '../Zustand';
import { debounce, throttle } from 'throttle-debounce';
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";


const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function DeckGLOverlay(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: false }));
    overlay.setProps(props);
    return null;
}

const icosphereMesh = new IcoSphereGeometry({});

const NODE_MATERIAL = {
    ambient: 0.7,
    diffuse: 0.6,
    shininess: 200,
    specularColor: [255, 255, 255],
    wireframe: true,
};

const INITIAL_VIEW_STATE = {
    "longitude": -1.9053006641463526,
    "latitude": 52.47992709031189,
    "zoom": 16.030968845856695,
    "pitch": 52.942010970722855,
    "bearing": -59.350466074324345,
}

const Atlas = ({ onSelectNode }) => {
    const { threads, setLight, zoomScope, setZoomScope, flyTo, setFlyTo, gameState } = Zustand.GlobalStore();
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)
    const mapRef = useRef();


    const locations = useMemo(() => threads[threads.length - 1]?.content?.worldLocations, [threads])
    const relationships = useMemo(() => threads?.filter(x => x?.relations).map((x, idx) => x.relations.map(y => ({ ...y, idx: idx, center: [y.long, y.lat] }))).reverse().flat().filter(x => x.idx === threads.length / 2 - 1), [threads])


    const setMapLight = (light) => {
        const mode = (light) => {
            switch (light) {
                case 'dawn':
                    return {
                        range: [0.8, 8],
                        color: '#dc9f9f',
                        'horizon-blend': 0.15,
                        'high-color': '#245bde',
                        'space-color': '#000000',
                        'star-intensity': 0.25,
                    };
                case 'day':
                    return { 'star-intensity': 0.05 };
                case 'dusk':
                    return {
                        range: [0.8, 8],
                        color: '#dc9f9f',
                        'horizon-blend': 0.2,
                        'high-color': '#245bde',
                        'space-color': '#000000',
                        'star-intensity': 0.45,
                    };
                case 'night':
                    return {
                        range: [0.8, 8],
                        color: '#001d3b',
                        'horizon-blend': 0.03,
                        'high-color': '#245bde',
                        'space-color': '#000000',
                        'star-intensity': 0.65,
                    };
                default:
                    return {};
            }
        };
        setLight(light);
        setFog(mode(light));
        if (light !== mapRef.current?.getConfigProperty('basemap', 'lightPreset')) {
            mapRef.current?.setConfigProperty('basemap', 'lightPreset', light);
            mapRef.current?.getMap().setFog(mode(light));
        }
    };

    const [fog, setFog] = useState({
        range: [0.8, 8],
        color: '#dc9f9f',
        'horizon-blend': 0.2,
        'high-color': '#245bde',
        'space-color': '#000000',
        'star-intensity': 0.45,
    });



    useEffect(() => {
        if (locations?.length > 0) {
            setFlyTo({ center: gameState?.player?.center, bearing: 0 });
        }
    }, [locations]);

    const onMapLoad = useCallback(() => {
        if (mapRef.current) {
            mapRef.current.once('load', () => {
                debounceSetMapLight(gameState.timeOfDay);
                mapRef.current?.getMap().setFog(fog);
                mapRef.current?.getMap().setProjection({ name: 'globe' }); // Enable globe projection
                mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                // mapRef.current?.getMap().setAtmosphere({
                //     color: 'rgba(135, 206, 235, 0.5)', // Sky color
                //     hazeColor: 'rgba(255, 255, 255, 0.5)', // Haze color
                //     hazeDensity: 0.5,
                //     hazeFade: 0.1,
                //     hazeStrength: 0.5,
                // });
                setFlyTo({
                    center: [-0.13322749256912453, 51.50211420390433],
                    essential: true,
                    duration: 240000,
                    zoom: 3, // Adjusted for globe view
                    pitch: 0,
                    bearing: 0,
                })
            });
        }
    }, [fog]);

    useEffect(() => {
        if (mapRef.current) {
            console.log('time change', gameState.timeOfDay)
            debounceSetMapLight(gameState.timeOfDay);
        }
    }, [gameState.timeOfDay])

    useEffect(() => {
        setTimeout(() =>
            flyTo && mapRef.current.flyTo({
                ...flyTo
            })
            , 1000)
    }, [flyTo])

    const zoomScaler = useMemo(() => {
        switch (zoomScope) {
            case 'national': return 10000;
            case 'regional': return 2000;
            case 'local': return 250;
            default: return 29999;
        }
    }, [zoomScope])


    const scatterLayer = useMemo(() => new ScatterplotLayer({
        id: 'scatterplot-layer',
        visible: zoomScaler <= 2000 ? true : false,
        data: locations,
        getPosition: d => [...d.center, 400],
        getRadius: d => d.radius * 30,
        getFillColor: d => [65, 105, 225, d.idx === threads.length ? 255 : 30],
        pickable: true,
        updateTriggers: {
            data: [threads]
        },
        onClick: info => {
            if (info.object) {
                const { label, description, lat, long } = info.object;
                console.log(`Selected Node: ${label}`, info.object);
                setFlyTo({ zoom: info.object.zoom+2,center: info.object.center  })
                onSelectNode?.(info.object);
            }
        },
        onHover: info => {
            if (info.object) {
                const { label, description } = info.object;
                console.log(`Hovered Node: ${label}`, description);
            }
        },
    }), [threads, zoomScaler]);

    const meshLayer = useMemo(() => new SimpleMeshLayer({
        id: 'pointer-mesh-layer',
        visible: zoomScaler !== 30000 ? true : false,
        data: locations,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        getPosition: d => [...d.center, 400],
        getColor: d => [255, 140, 0, d.idx === threads.length ? 255 : 255],
        getOrientation: d => [0, 0, 0],
        wireframe: false,
        sizeScale: zoomScaler,
        opacity: 0.9,
        autoHighlight: true,
        pickable: true,
        highlightColor: d => [120, 140, 245, d.idx === threads.length ? 255 : 60],
        updateTriggers: {
            data: [threads]
        },
        onClick: info => {
            if (info.object) {
                const { label, description, lat, long } = info.object;
                console.log(`Selected Node: ${label}`, info.object);
                setFlyTo({ zoom: info.object.zoom+2, center: info.object.center })
                onSelectNode?.(info.object);
            }
        },
        onHover: info => {
            if (info.object) {
                const { label, description } = info.object;
                console.log(`Hovered Node: ${label}`, description);
            }
        },
    }), [threads, zoomScaler]);

    const meshWireLayer = useMemo(() => new SimpleMeshLayer({
        id: 'pointer-mesh-layer',
        visible: zoomScaler !== 30000 ? true : false,
        data: locations,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        getPosition: d => [...d.center, 400],
        getColor: d => [255, 255, 255, 180],
        getOrientation: d => [0, 0, 0],
        wireframe: true,
        sizeScale: zoomScaler,
        opacity: 0.9,
        updateTriggers: {
            data: [threads],
        },
    }), [threads, zoomScaler]);

    const textLayer = useMemo(() => new TextLayer({
        id: 'asset-text-layer',
        visible: zoomScaler !== 30000 ? true : false,
        data: locations,
        getPosition: d => [...d.center],
        getText: d => d.name,
        getPixelOffset: [0, 50],
        getColor: [30, 30, 30, 230],
        getBackgroundColor: [30, 30, 30, 230],
        getSize: 16,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        background: false,
        getBackgroundColor: [58, 60, 63, 100],
        outlineColor: [160, 160, 160, 220],
        outlineWidth: 2,
        fontSettings: { sdf: true, radius: 12, cutoff: 0.18, smoothing: 0.4 },
        pickable: true
    }), [threads, zoomScaler])

    // const arcLayer = new ArcLayer({
    //     id: 'ArcLayer',
    //     data: relationships,
    //     getSourcePosition: (d) => d.center,
    //     getTargetPosition: (d) => d.to.coordinates,
    //     getSourceColor: (d) => [65, 140, 220, 255],
    //     getTargetColor: (d) => [65, 140, 220, 255],
    //     getWidth: 12,
    //     pickable: true
    // });

    // const iconLayer = new IconLayer({
    //     id: 'icon-layer',
    //     data: gameState.worldLocations,
    //     pickable: true,
    //     iconAtlas: '../assets/locations/EternalLibraryLondon.webp', // Replace with your icon atlas
    //     iconMapping: {
    //         'marker-blue': { x: 0, y: 0, width: 128, height: 128, anchorY: 128 },
    //         'marker-red': { x: 128, y: 0, width: 128, height: 128, anchorY: 128 },
    //         'marker-green': { x: 256, y: 0, width: 128, height: 128, anchorY: 128 }
    //     },
    //     getIcon: d => d.icon,
    //     getPosition: d => [d.long, d.lat],
    //     getSize: 48,
    //     onClick: info => {
    //         if (info.object) {
    //             alert(`Clicked on: ${info.object.name}`);
    //         }
    //     }
    // });

    const layers = threads?.length ? [textLayer, meshLayer, meshWireLayer, scatterLayer] : [];

    const debounceSetViewState = throttle(200, setViewState, { noLeading: true })
    const debounceSetZoomScope = throttle(200, setZoomScope, { noLeading: true })
    const debounceSetMapLight = debounce(200, setMapLight);


    // const handleMouseMove = (event) => {
    //     // Get mouse position relative to the map canvas
    //     console.log(event)
    //     const { offsetX, offsetY, target } = event.originalEvent;
    //     const { clientWidth, clientHeight } = target;

    //     // Calculate bearing and pitch based on mouse position
    //     const bearing = (offsetX / clientWidth) * 360; // Rotation based on horizontal mouse movement
    //     const pitch = (offsetY / clientHeight) * 60; // Tilt based on vertical mouse movement

    //     // Update the view state
    //     setViewState((prevState) => ({
    //       ...prevState,
    //       bearing: bearing, // rotate based on mouse's X position
    //       pitch: pitch,     // tilt based on mouse's Y position
    //     }));
    //   };


    return (
        <Map
            ref={mapRef}
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={{ bearing: -270 }}
            mapStyle={"mapbox://styles/najiy/cm4lpd7sw001f01sac5q78b2u"}///"mapbox://styles/mapbox/standard"
            projection="globe" // Enable globe view
            attributionControl={true}
            onLoad={onMapLoad}
            maxPitch={60}
            fog={fog}
            // onViewStateChange={({ viewState }) => setViewState(viewState)}

            onMove={e => {
                if (parseInt(e.viewState.zoom) !== parseInt(viewState.zoom)) {
                    debounceSetViewState(e.viewState)
                }

                if (e.viewState.zoom < 5)
                    debounceSetZoomScope('global')
                else if (e.viewState.zoom <= 8)
                    debounceSetZoomScope('national')
                else if (e.viewState.zoom <= 12)
                    debounceSetZoomScope('regional')
                else
                    debounceSetZoomScope('local')

            }}
        // onMouseMove={handleMouseMove} 
        >
            <DeckGLOverlay layers={layers} />
        </Map>
    );
};

export default Atlas;
