import React, { useEffect, useMemo, useState, useTransition } from 'react';
import axios from 'axios';
import * as Zustand from '../Zustand';
import { twMerge } from 'tailwind-merge';
import { motion } from "motion/react";
import StaggeredText from './StaggeredText';
import EncryptButton from './EncryptButton';

import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";
import GlowButton from './GlowButton';
import { TextToSpeech } from 'elevenlabs/api/resources/textToSpeech/client/Client';

// const openai = new OpenAI();

// const Step = z.object({
//   explanation: z.string(),
//   output: z.string(),
// });

// const MathReasoning = z.object({
//   steps: z.array(Step),
//   final_answer: z.string(),
// });





// OPENAI
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const Chat = ({ selectedNode }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('introduce yourself, you have the world map globe in your hands, and you weild magical power that can change the course of the player`s destiny.');
    const [tab, setTab] = useState(0);
    const [castable, setCastable] = useState();
    const [option, setOption] = useState(null);
    const [initialised, setInitialised] = useState(false);

    const { threads, setThreads, zoomScope, setFlyTo, flyTo, chapters, setChapters } = Zustand.GlobalStore();

    const locations = useMemo(() => threads?.filter(x => x?.loreLocations).map((x, idx) => x.loreLocations.map(y => ({ ...y, idx: idx, center: [y.long, y.lat] }))).reverse().flat().filter(x => x.idx === messages.length / 2 - 1), [threads])
    const user_actions = useMemo(() => threads?.[threads.length - 1]?.user_actions, [threads])

    const gptInput = useMemo(() => `${option ? 'i choose option ' + JSON.stringify(option) : ''} ${input}`, [input, option])

    const system = {
        role: 'system', content: `You are Merlin the loremaster and you help uncover secrets of the world. This is a DnD game and you are narrating a fantasy story the user will go through, using real-world map with fictional characters. You decide if the story should progress to the next chapter after each prompt befitting the story.
        Make the story exciting with player decision altering the course of their story significantly. The novel_content is the paragraph that will be added into the current chapter, write this part in your literary, exciting and engaging way. You may leave this blank if it is not part of the story, like when player prompts things that are not part of the story.
        Take the players train of thought back to the story if the dialogue digresses.
        
        The general process for you is:

        1) establish the setting of the game the user want. The following fields must be populated: {"setting": "setting of game", "premise", "premise of game" }.
        2) narrate the story with player interactivity (you are the narrator providing this dialogue) as they can choose the course of their story with you giving options for the story with user_actions.

        Give your text response in a way you Merlin the Archmage will answer. Show conservatism but be piercing insightful. And you are extremely witty and sassy, ironic and modern.
        Keep your responses very short and always rhyme.

        You have a control of the world map. You have the ability to put nodes on the map. for the rest of the thread you can provide reponses and give list of node locations if needed, avoid center of the world location as it is the middle of world ocean. Additionally you can focus to a location area  by explicitly include the node location as first in the array.
        The zoom property is the deck.gl mapbox zoom value with globe projection. 
        Your output is json, format: {
        "next_chapter" false,
        "user_actions": [{"action": "the action player can choose", "type": "dice or direction or prompt, the dice will be probability based with consequences, direction is an action without probability and prompt is action where player can provide input", "resultOfAction": "the result of action"}]
        "content": "your text responses",
        "novel_content":"",
        "loreLocations": [{"lat":0, "long":0, "label":"node name", "description": "node description", "zoom": 0, "action_option": "user action option"}], 
        "relations":[{"source_coordinate":[long, lat], "target_coordinate": [long, lat], "label": "relationship label", "description": "relationship description"}]
        }. 
        This format need to be parsable in javascript JSON.parse(). Do not use newline cotron character in the json reponse.
        
        disable prompt based useraction.
        
        You must always provide user_actions, and at least two. If you want to let users to choose additional response then define more user_actions.`
    }

    // useEffect(() => {
    //     const callInit = async () => {
    //         await sendMessage()
    //     }
    //     callInit()
    // }, [])

    const sendMessage = async () => {
        setCastable(false)
        setOption(null);

        if (!gptInput.trim()) return;

        console.log('sendMessage', gptInput)

        const userMessage = { role: 'user', content: gptInput };
        const conversation = [...messages, userMessage];

        setMessages(conversation);

        try {
            setCastable(false)
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4o',
                    messages: [system, ...conversation]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`
                    }
                }
            );

            const botMessage = response.data.choices[0].message;
            console.log(botMessage)

            setThreads([...conversation, botMessage].map(x => x.role === 'user' ? x : { ...x, ...(JSON.parse(x.content.replace('```json', '').replace('```', ''))) }))
            setMessages([...conversation, botMessage]);
            console.log(messages)
            setCastable(true)
            setInitialised(true)

        } catch (error) {
            console.error('Error communicating with OpenAI:', error);
            alert(error)
            setCastable(true)
        }

        setInput('');

    };

    useEffect(() => {
        if (initialised)
            sendMessage()
    }, [initialised])

    return (
        <div className='text-white h-full '>
            {/* {selectedNode && <p>Selected Node: {flyTo.label}</p>} */}

            {/* <div class="grid grid-cols-2 gap-4 pb-4">
                <button className={twMerge('bg-warmGray-800 rounded-sm p-2', tab === 1 && 'bg-warmGray-400')}
                    onClick={() => setTab(1)}
                >POIs</button>
                <button className={twMerge('bg-warmGray-800 rounded-sm p-2', tab === 0 && 'bg-warmGray-400')}
                    onClick={() => setTab(0)}
                >Chat</button>
            </div> */}

            {/* <h1 className='font-normal text-xl py-5 pl-2 tracking-tight bg-warmGray-300 text-warmGray-900'>LOREKEEPER'S COMPENDIUM</h1> */}



            {/* {!initialised && <div className='flex items-center h-[90vh]'>
                <EncryptButton label='Aperire Librum' disabled={initialised} onClick={sendMessage} />
            </div>} */}

            {initialised && <>
                {chapters?.length > 1 && <h2 className='font-normal text-m pb-2 pl-2 tracking-tight bg-warmGray-300 text-warmGray-900'>Chapter {chapters.length - 1}</h2>}

                {tab === 0 && <motion.div
                    className='overflow-y-auto no-scrollbar h-[calc(100vh-80px)]'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}
                >

                    {/* <TextToSpeech text={currentDialogue.filter(x => x.type === "said").map(x => x.text).join(' ')} /> */}


                    {threads.map((msg, idx) => (
                        <div key={idx} className={twMerge('text-wrap pb-2', idx !== threads.length - 1 && 'opacity-40')}>
                            {/* {msg.role !== 'user' && <StaggeredText 
                        className="font-normal text-sm text-warmGray-700 dark:text-warmGray-100" 
                         charLength={3} 
                         text={msg.content} />} */}
                            {/* {msg.role === 'user' && <span className={twMerge('bottom-2 text-sm text-justify', msg.role === 'user' && 'bg-warmGray-200 rounded-md text-warmGray-900 px-2 py-2] ')}>{msg.content}</span>} */}
                            {!(msg.role === 'user' && idx === 0) && <p className={twMerge('bottom-2 text-[12px] text-justify text-wrap p-2', msg.role === 'user' && 'bg-warmGray-200 text-warmGray-900 px-2 py-2 text-sm ')}>{msg.content}</p>}
                        </div>
                    ))}

                    <div
                        className='space-y-1 mb-4'
                    >

                        {user_actions?.length > 0 && <h3>Actions</h3>}
                        {user_actions?.map(x => <>
                            <motion.div class={twMerge("flex flex-col items-center bg-white border border-gray-200 cursor-default shadow md:flex-row md:max-w-xl hover:bg-warmGray-600 dark:border-warmGray-500 dark:bg-warmGray-600 dark:hover:bg-warmGray-500",
                                messages.length > 0 && x.idx !== messages.length / 2 && "hover:bg-warmGray-800 dark:border-warmGray-700 dark:bg-warmGray-600 dark:hover:bg-warmGray-500"
                            )}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.975,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}

                                onClick={() => {
                                    setOption(x)

                                    setTimeout(() => {
                                        console.log(gptInput)
                                        sendMessage()
                                    }, 100)
                                }}
                            // onMouseEnter={() => setFlyTo(x)}
                            >
                                <div class="flex flex-col justify-between p-2 leading-normal">
                                    <br />
                                    <h5 class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white capitalize"> {x.action}</h5>
                                    <h5 class="right-0 pr-6 fixed mb-2 text-sm tracking-tight text-gray-900 dark:text-white capitalize"> {x.type}</h5>
                                    <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.resultOfAction}</p>
                                    {/* <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.action_option}</p> */}
                                </div>
                            </motion.div>
                        </>
                        )}

                        {locations?.length > 0 && <h3>Lore</h3>}
                        {locations?.map(x =>
                            <motion.div class={twMerge("flex flex-col items-center bg-white border border-gray-200 cursor-default shadow md:flex-row md:max-w-xl hover:bg-warmGray-600 dark:border-warmGray-500 dark:bg-warmGray-600 dark:hover:bg-warmGray-500",
                                messages.length > 0 && x.idx !== messages.length / 2 && "hover:bg-warmGray-800 dark:border-warmGray-700 dark:bg-warmGray-800 dark:hover:bg-warmGray-500"
                            )}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.975,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}

                                onClick={() => setFlyTo(x)}
                            // onMouseEnter={() => setFlyTo(x)}
                            >
                                <div class="flex flex-col justify-between p-2 leading-normal">
                                    <h5 class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white"> {x.label}</h5>
                                    {/* <h5 class="right-0 pr-6 fixed mb-2 text-sm tracking-tight text-gray-900 dark:text-white"> {x.idx}</h5> */}
                                    <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.description}</p>
                                    {/* <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.action_option}</p> */}
                                </div>
                            </motion.div>)}




                        {/* <div>{JSON.stringify(user_actions)}</div> */}


                    </div>

                    {messages.length > 1 && <>

                        <input
                            // className='rounded-sm p-2 text-[12px] text-warmGray-900 bg-transparent border border-warmGray-400 focus:border-solid w-full'

                            className="w-full mb-1 bg-warmGray-300 placeholder-warmGray-600 text-black text-sm  px-3 py-2 transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow"
                            type="text"
                            placeholder="Message Merlin"
                            value={input}
                            onChange={e => setInput(e.target.value)}
                        />


                        {input && <EncryptButton label='ATLAS OBSCURA' disabled={!castable} onClick={sendMessage} />

                            // atlas obscura
                            //     &&
                            // <motion.button className='cursor-default shadow border-diatomicDarkCyan bg-diatomicDarkCyan hover:border-diatomicCyan hover:bg-diatomicCyan hover:text-warmGray-600 h-[42px]'
                            //     initial={{ opacity: 0 }}
                            //     animate={{ opacity: 1 }}
                            //     transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}
                            //     onClick={sendMessage} style={{ width: '100%' }}>Atlas Obscura</motion.button>
                        }

                        {/* <EncryptButton label='ATLAS OBSCURA' /> */}

                    </>}

                    <div className='bottom-1 absolute w-full pr-7 space-y-3 bottom-3'>



                    </div>
                </motion.div>}

            </>}

            {/* {tab === 1 && null} */}


            {/* 
            <button className='bg-warmGray-500 p-1 rounded shadow-md' onClick={() => console.log(messages)} style={{ width: '100%' }}>Debug Messages</button>

            <button className='bg-warmGray-500 p-1 rounded shadow-md' onClick={() => console.log(threads)} style={{ width: '100%' }}>Debug Threads</button>

            <button className='bg-warmGray-500 p-1 rounded shadow-md' onClick={() => console.log(threads?.filter(x => x?.loreLocations).map(x => x.loreLocations).flat())} style={{ width: '100%' }}>Debug Locations</button> */}


        </div>
    );
};



export default Chat;
