import React, { useEffect, useMemo, useState, useTransition } from 'react';
import axios from 'axios';
import * as Zustand from '../Zustand';
import { clearPersistedState } from '../Zustand';
import { twMerge } from 'tailwind-merge';

import StaggeredText from './StaggeredText';
import EncryptButton from './EncryptButton';

import logo from '../assets/logo.png';
import { motion } from "motion/react";

import { debounce } from 'throttle-debounce';

import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";
import GlowButton from './GlowButton';
import TextToSpeechPlayer from './TextToSpeechPlayer';

import dice from '../assets/die.png';
import compass from '../assets/compass.png';
import book from '../assets/book.png';
import quill from '../assets/wand.png';


const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;

const API_URL = 'http://localhost:5000/api'; // Replace with your deployed API URL



export const saveData = async (token, data) => {
    try {
        const response = await fetch(`${API_URL}/data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        console.error('Error saving data:', error);
    }
};

export const fetchData = async (token, id) => {
    try {
        const response = await fetch(`${API_URL}/data/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const listData = async (token) => {
    try {
        const response = await fetch(`${API_URL}/data`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    } catch (error) {
        console.error('Error listing data:', error);
    }
};






const dialogueProcessor = (text) => {

    // Split the text by asterisks
    // console.log(JSON.stringify(text))
    const parts = text.split('*');

    // Process the parts into the desired JSON structure
    const result = parts.map((part, index) => {
        if (index % 2 === 0) {
            // Text outside asterisks is "said"
            return { type: "said", text: part.trim() };
        } else {
            // Text inside asterisks is "motion"
            return { type: "motion", text: part.trim() };
        }
    }).filter(item => item.text); // Remove any empty text entries

    return result;

}

const MyriadRealms = ({ selectedNode }) => {
    // const [messages, setMessages] = useState([]);
    const [input, setInput] = useState(null);
    const [tab, setTab] = useState(0);
    const [castable, setCastable] = useState(true);
    const [option, setOption] = useState(null);

    const { setGameState, gameState, threads, setThreads, zoomScope, setFlyTo, flyTo, magicalDate, timeOfDay, setAudioText, initialised, setInitialised } = Zustand.GlobalStore();


    const latestThreadContent = useMemo(() => threads[threads.length - 1]?.content, [threads])
    const newLocations = useMemo(() => latestThreadContent?.worldLocations, [threads])
    const user_actions = useMemo(() => latestThreadContent?.user_actions, [threads])
    const prompt_context = useMemo(() => latestThreadContent?.prompt_context, [threads])


    const gptInput = useMemo(() => `${option ? 'I choose option ' + JSON.stringify(option) : ''} ${input}`, [input, option])
    const audioText = useMemo(() => latestThreadContent?.content ? dialogueProcessor(latestThreadContent.content) : [], [threads])

    const systemPrompt = useMemo(() => `Output in JSON.

        You are Merlin the loremaster, narrating a DnD-style fantasy with fictional map names. The story is warm, ironic, heartfelt. You teach dragons poetry and cast illusions. Decide if each prompt advances the chapter. Player choices shape the plot. Keep responses short, ironic or warm. Write “novel_content” literarily and excitingly in third-person, or leave it blank if off-story. Redirect player if off-topic.

        At start: give player 5 spellscrolls (3–8 each), a weapon, and ask warm or ironic questions to determine 4 stats. Player only uses owned items. Risky actions (combat or not) can harm health. Combat is turn-based with damage from level/gear/spellscrolls. Show enemy HP in combat.

        Always offer ≥5 user_actions (either “directed” or “dice”), possibly more, DnD-style. Include ≥2 worldLocations. If inventory, gold, health, buffs, quests, playerTitle, playerIronicTitle, weapon, or npc relationships change, mention it. Level up if xp > nextLevelXp.

        You control a world map with precise non-zero lat/long nodes and a deck.gl mapbox zoom. Avoid the world’s center ocean. If travel >4 miles, time of day and magical date change. Time phases: dawn, day, dusk, night.

        The current state of the game: ${JSON.stringify({ ...gameState, "context_progression": threads.slice(Math.max(threads.length - 5, 1)) })}
        
        location JSON schema = {"center":[longitude,latitude],"radius":meters,"iconBgColor":"#hexColor","label":"Name","description":"Lore","zoom":0,"action_option":"option","real_world_location":"Real-world counterpart"}
        All location lat long coordinates must be very accurate and precise to at least 5 decminal places. If player visits a shop, you must remember the item and its price and location. If player travels and time advances, you must narrate the journey and mention if any events unfolds.

        actor JSON schema = {"name":"","age":"","race":"","iconBgColor":"#hexColor","health":"","legendary":"","gender":"","traits":"One trait","relationship":"to player","job":"","center":[longitude,latitude],"motivation":"","affiliation":"","stats":[],"backstory":""}

        item JSON schema = {"name":"","quantity":number,"effect":"","value":number}

        This need to output explicitly the updated game state: 
        {
            "context_progression":array of scene from previous interactions details,
            "npc": [Actor Schema],
            "timeOfDay":"dawn/day/dusk/night",
            "introduced":true/false,
            "worldLocations":[Location Schema],
            "playerLocation":[longitude, latitude],
            "chapter":number,
            "quests":[{"name":"","status":"hidden/active/completed","requirements":[],"potentialReward":[]}],
            "magicalDate":"e.g. Age of Fire + dragon days",
            "health":number,
            "playerTitle":"Earned title",
            "playerIronicTitle":"Ironic title",
            "memories":["future recall"],
            "inventory":[{"name":"","description":"","effect":"","quantity":number,"value":number}],
            "buffs":[{"name":"","effect":"","duration":number}],
            "stats":[{"name":"","effect":"","value":number}],
            "spellscrolls":[{"name":"","quantity":number,"effect":"","value":number}],
            "gold":number,
            "xp":number,
            "nextLevelXp":number,
            "lvl":number,
            "weapon":{"name":"","ironicName":"","effects":"","value":"","durability":100},
            "user_actions":[{"action":"","type":"prompt/dice/directed","result_of_action":"","scene_center":[longitude,latitude]}],
            "content":"First-person dialogue",
            "novel_content":["Third-person narrative entries"]
            }

        
        This format need to be parsable in javascript JSON.parse(), undefined values must be null.
`, [threads, timeOfDay, magicalDate, prompt_context])

    const sendMessage = debounce(300, async (text) => {
        let production = true
        let api = false ? `${production ? "" : "http://localhost:9001/taleweaver-400bd/us-central1"}/app/api/gpt` : `${production ? "" : "http://localhost:9001/taleweaver-400bd/us-central1"}/app/api/claude`
        let model = false ? "gpt-4o" : "claude-3-5-sonnet-latest"

        setCastable(false);

        if (!gptInput.trim()) return;

        console.log('sendMessage', gptInput);
        console.log('systemPrompt', systemPrompt);

        const userMessage = { role: 'user', content: gptInput };

        // `${systemPrompt}\n\nUser: ${gptInput}\n\n`,
        console.log(api)
        console.log("sending to", api, threads.slice(Math.max(threads.length - 14, 1)).map(x => x.content.content || x.content))


        try {
            setCastable(false);

            // const attemptRequest = async (attempt = 1) => {
            try {
                const response = await axios.post(
                    api, // Proxy endpoint
                    {
                        model: model,
                        game_state: { ...gameState, context_progression: threads.slice(Math.max(threads.length - 12, 1)).map(x => x.content.content || x.content) },
                        prompt: { system: null, user: text || gptInput },
                        max_tokens_to_sample: 1000,
                    }
                );

                setOption(null);

                // console.log(response.data);
                console.log('game update', response.data);

                console.log('game state?', response.data.content)

                console.log('game threads?', response.data)

                setThreads([...threads, userMessage, response.data]);
                setGameState(response.data.content)
                setCastable(true);
                setInitialised(true);
                setInput('');

            } catch (error) {
                console.error(`Error communicating with Claude:`, error);
                // if (attempt < 3) {
                //     console.log(`Retrying... Attempt ${attempt + 1}`);
                //     setTimeout(() => attemptRequest(attempt + 1), 300); // Retry after 1 second
                // } else {
                //     alert('Failed to communicate after 3 attempts.');
                setCastable(true);
                setInitialised(false);
                // }
            }
            // };

            // attemptRequest(); // Start the first attempt

        } catch (error) {
            console.error('Unexpected error:', error);
            setCastable(true);
            setInitialised(false);
        }
    });




    useEffect(() => {
        setAudioText(audioText.filter(x => x.type === "said").map(x => x.text).join(" "))
        setTab(1)
    }, [threads])

    useEffect(() => { if (option !== null) sendMessage() }, [option])

    useEffect(() => {
        if (initialised && threads.length === 0) {
            sendMessage("introduce yourself")
        }
    }, [initialised])



    const handleClear = async () => {
        await clearPersistedState();
        // Now the store is cleared from localStorage and reset
    }

    return (
        <div className='text-white h-full overflow-y-hidden pb-20'>

            {/* <div className='fixed'>
                Weapon
            </div> */}

            <button className='p-1 bg-warmGray-700 opacity-30 hover:opacity-100 text-sm fixed' onClick={handleClear}>
                Start New World
            </button>


            {initialised && <>

                <motion.div
                    className='overflow-y-auto no-scrollbar px-4 h-[100vh] pb-10'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}
                >

                    <div className='h-[40px]' />


                    {gameState.magicalDate && gameState.timeOfDay && <h2 className='text-[14px] p-2 tracking-tight bg-warmGray-300 text-warmGray-900 capitalize items-center text-center'
                        onClick={() => console.log(gameState)}>
                        {gameState.magicalDate}. The {gameState.timeOfDay}
                    </h2>}

                    {gameState.player.playerIronicTitle && gameState.player.playerTitle && <h2 className='text-[14px] p-2 tracking-tight bg-warmGray-300 text-warmGray-900 capitalize items-center text-center'
                        onClick={() => console.log(gameState)}>
                        {gameState.player.playerTitle}: {gameState.player.playerIronicTitle}.
                    </h2>}

                    {gameState.player.weapon?.name && gameState.player.weapon?.ironicName && <h2 className='text-[14px] p-2 tracking-tight bg-warmGray-300 text-warmGray-900 capitalize items-center text-center'
                        onClick={() => console.log(gameState)}>
                        {gameState.player.weapon.name}: {gameState.player.weapon.ironicName}.
                    </h2>}

                    {gameState.player.lvl && gameState.player.health && gameState.player.fatigue && typeof gameState.player.gold === 'number' && typeof gameState.player.xp === 'number' && gameState.player.nextLevelXp && <h2 className='text-[14px] p-2 tracking-tight bg-warmGray-300 text-warmGray-900 capitalize items-center text-center'
                        onClick={() => console.log(threads)}>
                        Lvl  {gameState.player.lvl}. {gameState.player.health} Health. {gameState.player.fatigue} Fatigue. {gameState.player.gold} Gold. {gameState.player.xp}/{gameState.player.nextLevelXp} Xp.
                    </h2>}


                    {/* {threads.filter(x => x).map((msg, idx) => (
                        <div key={idx} className={twMerge('text-wrap pb-2', idx !== threads.length - 1 && 'opacity-40')}> */}
                    {/* {msg.role !== 'user' && <StaggeredText 
                        className="font-normal text-sm text-warmGray-700 dark:text-warmGray-100" 
                         charLength={3} 
                         text={msg.content} />} */}
                    {/* {msg.role === 'user' && <span className={twMerge('bottom-2 text-sm text-justify', msg.role === 'user' && 'bg-warmGray-200 rounded-md text-warmGray-900 px-2 py-2] ')}>{msg.content}</span>} */}
                    {/* {!(msg.role === 'user' && idx === 0) && <p className={twMerge('bottom-2 text-[12px] text-justify text-wrap p-2', msg.role === 'user' && 'bg-warmGray-200 text-warmGray-900 px-2 py-2 text-sm ')}>{msg.content}</p>}
                        </div>
                    ))} */}



                    {/* <button onClick={() => console.log(threads)}>df</button> */}

                    {/* {JSON.stringify(castable)} */}




                    {/* <div
                        className='space-y-1 my-2 w-full'
                    > */}

                    <br />

                    {threads?.length % 2 === 0 && threads[threads.length - 1]?.content && <pre className='px-4 py-2'>{threads[threads.length - 1].content.novel_content}</pre>}

                    {audioText?.length > 0 && audioText.map(x => {
                        switch (x.type) {
                            case "motion": return null; /// <i className={twMerge('bottom-2 text-[12px] text-justify text-wrap p-8')}>* {x.text} *</i>
                            case "said": return <pre className='px-4 py-2'>{x.text}</pre>
                            default: return <p>{x.text}</p>
                        }
                    })}

                    <br />

                    {initialised && !castable && <div role="status" className='w-full text-center scale-150 p-6'>
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>}

                    {castable && <div class="grid grid-cols-2 gap-1 mt-2 mb-4">

                        {/* <button className={twMerge('bg-warmGray-800', tab === 0 && 'w-full bg-warmGray-300 placeholder-warmGray-600 text-black text-sm transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow')}
                                onClick={() => setTab(0)}
                            >Story</button> */}

                        <button className={twMerge('bg-warmGray-800 py-2', tab === 1 && 'w-full bg-warmGray-300 placeholder-warmGray-600 text-black text-sm transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow')}
                            onClick={() => setTab(1)}
                        >Action</button>

                        <button className={twMerge('bg-warmGray-800 py-2', tab === 2 && 'w-full bg-warmGray-300 placeholder-warmGray-600 text-black text-sm transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow')}
                            onClick={() => setTab(2)}
                        >Lore</button>

                    </div>}

                    {castable && <>
                        {/* {user_actions?.length > 0 && <h3>Actions</h3>} */}




                        {tab === 1 && user_actions?.map(x => <>
                            <motion.div class={twMerge(" items-center bg-white border border-gray-200 cursor-default shadow hover:bg-warmGray-600 dark:border-warmGray-500 dark:bg-warmGray-600 dark:hover:bg-warmGray-500",
                                threads.length > 0 && x.idx !== threads.length / 2 && "hover:bg-warmGray-800 dark:border-warmGray-700 dark:bg-warmGray-600 dark:hover:bg-warmGray-500"
                            )}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.975,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}

                            >
                                <div class="p-2 grid grid-cols-5"
                                    onClick={() => {
                                        if (x.type === "prompt" && !input)
                                            alert("Your must freewrite then you can pick this option!")
                                        else
                                            setOption(JSON.stringify(x))
                                    }}
                                >
                                    {/* <div className=''> */}
                                    {/* <br /> */}
                                    <img className="h-[40px] pr-4 m-auto" src={x.type === "directed" ? compass : x.type === "dice" ? dice : quill} alt="Logo" />
                                    {/* </div> */}
                                    <div className='col-span-4'>
                                        <h5 class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white capitalize"> {x.action}</h5>

                                        {/* <h5 class="right-0 pr-6 fixed mb-2 text-sm tracking-tight text-gray-900 dark:text-white capitalize"> {x.type}</h5> */}
                                        <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.result_of_action}.</p>
                                    </div>

                                </div>
                            </motion.div>
                        </>
                        )}

                        {/* {locations?.length > 0 && <h3>Lore</h3>} */}
                        {tab === 2 && newLocations?.map((x, idx) =>
                            <motion.div class={twMerge("items-center bg-white border border-gray-200 cursor-default shadow hover:bg-warmGray-600 dark:border-warmGray-500 dark:bg-warmGray-600 dark:hover:bg-warmGray-500",
                                threads.length > 0 && threads.length % 2 === 0 && "hover:bg-warmGray-800 dark:border-warmGray-700 dark:bg-warmGray-800 dark:hover:bg-warmGray-500"
                            )}
                                whileHover={{
                                    scale: 1.015,
                                }}
                                whileTap={{
                                    scale: 0.975,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}

                                onClick={() => setFlyTo({ ...x, zoom: x.zoom, bearing: 0 })}
                            >
                                <div class="p-2 grid grid-cols-5">
                                    <img className="h-[40px] pr-4 m-auto" src={book} alt="Logo" />
                                    <div className='col-span-4'>
                                        <h5 class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white"> {x.name} <i>({x.real_world_location})</i></h5>
                                        <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {x.description}</p>
                                    </div>
                                </div>
                            </motion.div>)}

                        {/* <div>{JSON.stringify(user_actions)}</div> */}
                    </>}


                    {threads.length > 0 && castable && <>

                        <input
                            // className='rounded-sm p-2 text-[12px] text-warmGray-900 bg-transparent border border-warmGray-400 focus:border-solid w-full'

                            className="w-full bg-warmGray-300 placeholder-warmGray-600 text-black text-sm  px-3 py-5 transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow"
                            type="text"
                            placeholder="Freewrite..."
                            value={input}
                            onChange={e => setInput(e.target.value)}
                        />

                        {input && <motion.div class={twMerge("items-center bg-white border border-gray-200 cursor-default shadow hover:bg-warmGray-600 dark:border-warmGray-500 dark:bg-warmGray-600 dark:hover:bg-warmGray-500")}
                            whileHover={{
                                scale: 1.015,
                            }}
                            whileTap={{
                                scale: 0.975,
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}

                            onClick={() => sendMessage(null)}
                        >
                            <div class="p-2 grid grid-cols-5">
                                <img className="h-[40px] pr-4 m-auto" src={quill} alt="Logo" />
                                <div className='col-span-4'>
                                    <h5 class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white"> I Digress </h5>
                                    <p class="font-normal text-[12px] text-warmGray-700 dark:text-warmGray-100"> {input}</p>
                                </div>
                            </div>
                        </motion.div>}
                        {/* <EncryptButton label='I Digress!' disabled={!castable} onClick={sendMessage} />} */}

                    </>}

                    {/* </div> */}






                    {/* <div class="relative flex h-10 w-full">
                        <button
                            class="!absolute right-1 top-1 z-10 select-none bg-warmGray-300 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
                            type="button"
                            data-ripple-light="true"
                        >
                            Invite
                        </button>
                        <input
                            // className='rounded-sm p-2 text-[12px] text-warmGray-900 bg-transparent border border-warmGray-400 focus:border-solid w-full'

                            className="w-full mb-1 bg-warmGray-300 placeholder-warmGray-600 text-black text-sm  px-3 py-2 transition duration-300 ease focus:outline-none focus:border-warmGray-50 hover:border-warmGray-50 shadow-sm focus:shadow"
                            type="text"
                            placeholder="Custom Prompt..."
                            value={input}
                            onChange={e => setInput(e.target.value)}
                        />
                        <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-warmGray-300 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-warmGray-300 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-warmGray-300 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                            Custom Prompt
                        </label>
                    </div> */}

                </motion.div>

            </>}

        </div>
    );


};

export default MyriadRealms;
