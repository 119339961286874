import { useEffect } from 'react';
import { GoogleLogout } from 'react-google-login';


{/* <script src="https://www.gstatic.com/firebasejs/8.0/firebase.js"></script>
<script>
  var config = {
    apiKey: "AIzaSyB61vgBsgwuGngndnDuzPvqtZF8RqKxaYw",
    authDomain: "taleweaver-444100.firebaseapp.com",
  };
  firebase.initializeApp(config);
</script>*/}



const clientId = process.env.GOOGLE_SSO_CLIENTID
// const clientSec: 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

function Logout({ onSuccess, onFailure }) {

    useEffect(()=> console.log('client id', clientId),[])

    const onSuccessDefault = (res) => {
        console.log("LOGOUT SUCCESS! user: ", res)
    }

    const onFailureDefault = (res) => {
        console.log("LOGIN FAILED! user: ", res)
    }

    return (<div id="signOutButton">
        <GoogleLogout
            clientId={clientId}
            buttonText='Logout'
            onLogoutSuccess={res => onSuccess? onSuccess(res): onSuccessDefault(res)}
            onFailure={res => onFailure? onFailure(res): onFailureDefault(res)}
        />
    </div>)
}

export default Logout