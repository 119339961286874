export const game_schema = {
  "context_progression": "",
  "scene_visual_background": "",
  "npc": [
    {
      "name": "",
      "age": "",
      "race": "",
      "iconBgColor": "#HEXCOLOR",
      "health": "",
      "legendary": "",
      "gender": "",
      "traits": "",
      "relationship": "",
      "job": "",
      "center": [0.0, 0.0],
      "motivation": "",
      "affiliation": "",
      "stats": [
        {
          "name": "",
          "effect": "",
          "value": 0
        }
      ],
      "backstory": "",
      "imageUniqueId": "",
      "imageDescription": ""
    }
  ],
  "timeOfDay": "dawn",
  "introduced": false,
  "worldLocations": [
    {
      "center": [0.0, 0.0],
      "radius": 0,
      "iconBgColor": "#HEXCOLOR",
      "name": "",
      "description": "",
      "zoom": 0,
      "action_option": "",
      "real_world_location": "",
      "imageUniqueId": "",
      "imageDescription": "",
      "subLocations": [
        {
          "name": "",
          "description": "",
          "center": [0.0, 0.0],
          "iconBgColor": "#HEXCOLOR",
          "radius": 0,
          "zoom": 0,
          "action_option": "",
          "real_world_location": "",
          "imageUniqueId": "",
          "imageDescription": ""
        }
      ]
    }
  ],

  "player": {
    "center": [0.0, 0.0],
    "energy": 100,
    "mbti": {"type": "", likelihood: 0},
    "imageUniqueId": "",
    "imageDescription": "",
    "health": 100,
    "playerTitle": "",
    "playerIronicTitle": "",
    "memories": [],
    "inventory": [
      {
        "name": "",
        "description": "",
        "effect": "",
        "quantity": 0,
        "value": 0,
        "imageUniqueId": "",
        "imageDescription": "",
        "weight": 0
      }
    ],
    "buffs": [
      {
        "name": "",
        "effect": "",
        "duration": 0
      }
    ],
    "stats": [
      {
        "name": "",
        "effect": "",
        "value": 0
      }
    ],
    "spellscrolls": [
      {
        "name": "",
        "quantity": 0,
        "effect": "",
        "value": 0,
        "imageUniqueId": "",
        "imageDescription": "",
        "weight": 0
      }
    ],
    "gold": 0,
    "xp": 0,
    "nextLevelXp": 100,
    "lvl": 1,
    "weapon": {
      "name": "",
      "ironicName": "",
      "effects": "",
      "value": "",
      "durability": 100,
      "imageUniqueId": "",
      "imageDescription": "",
      "weight": 0
    },
    "weaponStorage": [
      {
        "name": "",
        "ironicName": "",
        "effects": "",
        "value": "",
        "durability": 100,
        "imageUniqueId": "",
        "imageDescription": "",
        "weight": 0
      }
    ],
    "factionReputations": [
      {
        "factionName": "",
        "reputationScore": 0
      }
    ],
    "discoveredClues": [
      {
        "clueName": "",
        "description": "",
        "solved": false
      }
    ]
  },

  "chapter": 1,
  "quests": [
    {
      "name": "",
      "status": "hidden",
      "requirements": [],
      "potentialReward": [],
      "factionInvolvement": "",
      "moralDilemmas": [
        {
          "description": "",
          "consequences": ""
        }
      ],
      "timeSensitive": false
    }
  ],

  "magicalDate": "",
  "user_actions": [
    {
      "action": "",
      "type": "prompt",
      "result_of_action": "",
      "center": [0.0, 0.0],
      "name": "",
      "imageUniqueId": "",
      "imageDescription": ""
    }
  ],
  "content": "",
  "novel_content": "",

  "weather": {
    "type": "",
    "effects": "",
    "duration": 0
  },
  "randomEvents": [
    {
      "triggerCondition": "",
      "description": "",
      "possibleOutcomes": []
    }
  ],
  "factions": [
    {
      "name": "",
      "description": "",
      "affiliation": "",
      "influence": "",
      "playerReputationModifiers": [
        {
          "modifier": 0
        }
      ]
    }
  ],
  "legendaryCreaturesCodex": [
    {
      "creatureName": "",
      "description": "",
      "knownWeaknesses": [],
      "sightings": [
        {
          "center": [0.0, 0.0],
          "timeOfDaySpotted": "",
          "additionalNotes": "",
          "name": "",
          "imageUniqueId": "",
          "imageDescription": ""
        }
      ]
    }
  ],
  "enemyCodex": [
    {
      "enemyName": "",
      "health": 0,
      "threatLevel": "",
      "preferredAttack": "",
      "loots": [
        {
          "name": "",
          "quantity": 0,
          "value": 0,
          "imageUniqueId": "",
          "imageDescription": "",
          "weight": 0
        }
      ]
    }
  ]
}
